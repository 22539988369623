
































































































































































































































































































































































































































.min-125 {
  min-width: 125px;
}
.auth-title {
  text-align: center;
  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
  }
  p {
    color: #fff;
  }
}
.login-form {
  min-width: 274px;
  max-width: calc(100% - 50px);
  span {
    &.effect {
      background: #009750;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
    }
    &.eggsbook {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
      color: #5d5d5d;
    }
  }
  .top-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 35px auto 20px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      flex: 0 0 100px;
      min-width: 100px;
      img {
        height: auto;
        width: 250px;
        margin-bottom: 5px;
      }
    }
    .hint-section {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: #212529;
      .hint-main {
        font-weight: 900;
        font-size: clamp(20px, 1.5vw, 22px);
        line-height: 120%;
        margin-bottom: 5px;
      }
      .hint-sub {
        font-size: clamp(14px, 1vw, 16px);
        color: #98a6ad;
        line-height: 115%;
        font-weight: 600;
      }
      span {
        &.effect {
          background: #009750;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: clamp(20px, 1.5vw, 22px);
          text-transform: uppercase;
          font-weight: 900;
          line-height: 120%;
        }
        &.eggsbook {
          font-size: clamp(18px, 1.5vw, 20px);
          text-transform: uppercase;
          font-weight: 900;
          color: #5d5d5d;
          line-height: 120%;
        }
      }
    }
  }
  .form-section {
    max-width: 100%;
    margin: 0 auto;
    label {
      font-weight: 400;
      color: #fff;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .input-group-text {
      background: transparent;
      padding: 0;
      border: 1px solid #fff;
      width: 37px;
      height: 37px;
      display: flex;
      color: #fff;
      justify-content: center;
      align-items: center;
    }
    select,
    input {
      border: 1px solid #e9ecef;
      height: 37px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      padding: 0.375rem 0.75rem;
      border-radius: 5px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      max-width: calc(100% - 45px);
      &:focus-visible,
      &:focus {
        box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        outline: none;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
    .form-group {
      .forgot {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        i,
        svg {
          margin-right: 5px;
          color: #98a6ad;
          font-size: clamp(14px, 1.5vw, 16px);
        }
        span a {
          color: #fff;
          font-weight: 600;
          font-size: clamp(14px, 1.5vw, 16px);
        }
      }
    }
  }
  button {
    margin-top: 20px;
    &.btn-auth {
      border: 1px solid #008cff;
      box-shadow: 0 2px 6px 0 #008cff;
      letter-spacing: 0.05rem;
      position: relative;
      background: #008cff;
      color: #fff !important;
      min-width: 130px;
      width: 100%;
      height: 40px;
      overflow: hidden;
      transition: 0.3s ease-in-out;
      border-radius: 0.3rem;
      z-index: 1;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .other-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h6 {
      color: #5d5b6f;
      font-size: clamp(15px, 1vw, 16px);
      font-weight: 600;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        display: inline-block;
        margin: 0 14px 4px 0;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
      &:after {
        display: inline-block;
        margin: 0 0 4px 14px;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
    }
    button {
      margin-top: 20px;
      border-radius: 50%;
      border: 0;
      background: linear-gradient(45deg, #7ac14e73, #0f9c4f70);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      color: #fff;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        background: #009750;
      }
    }
    .redirect-section {
      font-weight: 600;
      h5 {
        font-size: clamp(14px, 1.5vw, 16px);
        font-weight: 600;
        color: #fff;
      }
      a {
        margin: 20px auto;
        border-radius: 5px;
        border: 0;
        background: #009750;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 40px;
        text-decoration: none;
        color: #fff;
        &:hover,
        &:focus,
        &:focus-visible {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
